import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id="experience">
    <h5> What skills do i have </h5>
    <h2> My Experience </h2>

  <div className="container experience__container">

    <div className="experience__frontend">
      <h3> Frontend development </h3>
      <div className="experience__content">
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
       <div>  
          <h4> HTML</h4>
          <small className='text-light'> Experience </small>
        </div>
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
       <div>  
          <h4> Css</h4>
          <small className='text-light'> Experience </small>
        </div>
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
       <div> 
          <h4> Javascript</h4>
          <small className='text-light'> Experience </small>
       </div>
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
        <div>
          <h4> Bootstrap</h4>
          <small className='text-light'> Experience </small>
        </div>
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
      <div> 
        <h4> React</h4>
        <small className='text-light'> Experience </small>
      </div>
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
      <div> 
        <h4> JavaFX
        </h4>
        <small className='text-light'> Experience </small>
      </div>
      </article>
      </div>
      </div>
     


      <div className="experience__backend">
      <h3> Backend development </h3>
      <div className="experience__content">
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
        <div> 
          <h4> Node JS</h4>
          <small className='text-light'> Experience </small>
        </div>
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
        <div>
        <h4> MongoDB</h4>
        <small className='text-light'> Experience </small>
        </div>
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
        <div> 
        <h4> MySQL</h4>
        <small className='text-light'> Experience </small>

        </div>
        
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
        <div>
        <h4> Python</h4>
        <small className='text-light'> Experience </small>
        </div>
      </article>
      <article className="experience__details">
        <BsPatchCheckFill className="experience__details-icon" /> 
        <div>
        <h4> Java</h4>
        <small className='text-light'> Experience </small>
        </div>
      </article>
      </div>
      </div> 
      </div>
  </section>
  ) 
} 

export default Experience