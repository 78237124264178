import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id="services">
      <h5> what i offer </h5>
      <h2> Services </h2>

      <div className="container services__container">
        
        <article className="services">
          <div className="service__head">
            <h3> UI/UX Design </h3>
          </div>
            <ul className="service__list">
              <li>
                <BiCheck className='service__list-icon'/>
                <p>Design</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'/>
                <p>Strategy</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'/>
                <p>Usability</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'/>
                <p>Validation</p>
              </li>
            </ul>
        </article>
          {/*UI/UX */}
        <article className="services">
          <div className="service__head">
            <h3> Front-end Development</h3>
          </div>
            <ul className="service__list">
              <li>
                 <BiCheck className='service__list-icon'/>
                <p> HTML, CSS, and JavaScript</p>
              </li>
              <li>
                 <BiCheck className='service__list-icon'/>
                <p> Responsive Design:</p>
              </li>
              <li>
              <BiCheck className='service__list-icon'/>
                <p> Accessibility </p>
              </li>
              <li>
              <BiCheck className='service__list-icon'/>
                <p>Cross-Browser Compatibility</p>
              </li>
              <li>
              <BiCheck className='service__list-icon'/>
                <p> Performance Optimization</p>
              </li>
            </ul>
        </article>
          {/*front_end Development */}
        
        <article className="services">
          <div className="service__head">
            <h3>  backend development </h3>
          </div>
            <ul className="service__list">
              <li>
              <BiCheck className='service__list-icon'/>
                <p> Server-side Programming</p>
              </li>
              <li>
              <BiCheck className='service__list-icon'/> 
                <p>Database Management</p>
              </li>
              <li>
              <BiCheck className='service__list-icon'/>
                <p> API Development</p>
              </li>
              <li>
              <BiCheck className='service__list-icon'/>
                <p> Frameworks and Libraries</p>
              </li>
              <li>
              <BiCheck className='service__list-icon'/>
                <p>Security</p>
              </li>
            </ul>
        </article>

        {/*Backend Development*/}



        </div>
    </section>
  )
}

export default Services