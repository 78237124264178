import React from 'react'
import './about.css'
import ME from '../../assets/bilder.png'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id="about">
      <h5> Get to know me </h5>
      <h2> About me </h2>

    <div className="container about__container">
      <div className="about__me">
        <div className="about__me-image">
          <img src={ME} alt="me" ></img>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              < FaAward  className="about__icons"/>
              <h5> Experience </h5>
              <small> 6 months working  </small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icons"/>
              <h5> Projects </h5>
              <small> 20+ Completed  </small>
            </article>
          </div>
          <p>
          Hi there! My name is Yaqub Said and I'm a developer based in Bærum, Norway. I recently graduated from Høgskolen i Østfold with a degree in Computer Science and I'm excited to take on new challenges in the world of programming.

My passion for coding started at a young age when I was introduced to the world of computers. As I grew older, I began to explore different programming languages and discovered my love for creating innovative solutions to complex problems. Through my studies at Høgskolen i Østfold, I honed my skills in software development, web design, and database management.

In addition to my formal education, I have gained practical experience through bachelor assignment and freelance projects. I have worked on projects ranging from mobile app development to website redesigns, and I have collaborated with clients from various industries to create tailored solutions that meet their unique needs. </p>

<br />

<p> As a developer, I am committed to staying up-to-date with the latest trends and technologies in the field. I am always eager to learn and expand my skillset to deliver top-quality results to my clients. Whether you're looking to build a new website from scratch or need help with an existing project, I am here to provide reliable and efficient solutions.

Thank you for taking the time to learn more about me. Please feel free to reach out if you have any questions or would like to discuss a potential project in the contact section. </p> 
          
            <a href="#contact" className="btn btn-primary"> Let´s Talk </a>
    </div>
    </div>

    </section> 
  )
}

export default About 