import React from 'react'
import './footer.css'
import {BsFacebook} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer> 
          <a href='#' className='footer__logo'> Yaqub Said</a>


          <ul className='permalinks'>
            <li> <a href='#'> Home </a></li>
            <li> <a href='#about'> About </a></li>
            <li> <a href='#experience'> Experience</a></li>
            <li> <a href='#service'> Service </a></li>
            <li> <a href='#portfolio'> Portfolio </a></li>
            <li> <a href='#contact'> Contact </a></li>
          </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/yaqub.said.12"> <BsFacebook/> </a>
        <a href="https://www.instagram.com/yaqu_said/"> <AiFillInstagram /> </a>
      </div>

      <div className="footer_copyright">
        <small>&copy; Yaqub Said Portfolio Website </small>
      </div>
    </footer>

  )
}

export default Footer