import React from 'react'
import './contact.css'
import {MdEmail} from 'react-icons/md'
import {BsMessenger} from 'react-icons/bs'
import {IoLogoWhatsapp} from 'react-icons/io'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_nq2mxjh', 'template_xp73lfi', form.current, 'ubUHrKG7WEuhN2Pup')
      .then((result) => {
          console.log(result.text);
          
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  return (
    <section id="contact">
      <h5> Get In Touch</h5>
      <h2> Contact Me </h2>

    <div className="container contact__container">
      <div className="contact__options">
        <article className='contact__option'> 
          <MdEmail className='contact__option-icon'/>
          <h4> Email</h4>
          <h5>yaqubsaid3@gmail.com </h5>
          <a href="mailto:yaqubsaid3@gmail.com"> Send a message</a>
        </article>
        <article className='contact__option'> 
          <BsMessenger className='contact__option-icon'/>
          <h4> Messenger</h4>
          <h5> Yaqub Said Ahmed </h5>
          <a href="https://m.me/yaqub.said.12"> Send a message</a>
        </article>

        <article className='contact__option'> 
          <IoLogoWhatsapp className='contact__option-icon'/>
          <h4> WhatsApp</h4>
          <h5>+4790212383</h5>
          <a href="https://api.whatsapp.com/send?phone+4790212383"> Send a message</a>
        </article>
      </div>
      <form ref={form} onSubmit={sendEmail}> 
      <input type="text" name="Name" placeholder='Your Full Name' required/>
      <input type="email" name="email" placeholder='Your email' required/>
      <textarea name="message" rows="7" placeholder='Your message' required></textarea>
      <button type='submit' className='btn btn-primary'> Send message </button>
      </form>
 
    </div>
  </section>
  )
}

export default Contact