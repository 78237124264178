import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'


const Portfolio = () => {
  return (
    <section>
     <h5> My recent Work </h5>
     <h2> Portfolio</h2>

      <div className="container portfolio__container">



        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG1} alt="bachelor oppgave" />
          </div>
          <h3> Website for Telemedicine Language Training for Aphasia </h3>
        <div className="portfolio__item-cta">
        <a href="https://github.com/quber0161/bachelor-prototype" className='btn'>Github</a>

        </div>
        </article>

        
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG2} alt="bachelor oppgave" />
          </div>
          <h3> Android Social Media Application​ </h3>
        <div className="portfolio__item-cta">
        <a href="https://github.com/quber0161/KetchAppn" className='btn'>Github</a>
   
        </div>
        </article>


        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG3} alt="bachelor oppgave" />
          </div>
          <h3> 2D Game engine ​</h3>
        <div className="portfolio__item-cta">
        <a href="https://github.com/quber0161/GameEngine" className='btn'>Github</a>

        </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG4} alt="bachelor oppgave" />
          </div>
          <h3> Car Parking Application</h3>
        <div className="portfolio__item-cta">
        <a href="https://github.com/quber0161/SoftwareProtoType" className='btn'>Github</a>

        </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG5} alt="bachelor oppgave" />
          </div>
          <h3> Sanity & React API </h3>
        <div className="portfolio__item-cta">
        <a href="https://github.com/quber0161/oppgave-8" className='btn'>Github</a>
    
        </div>
        </article>
      </div>
    </section>
  )
}

export default Portfolio